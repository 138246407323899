.description-box[data-v-40224f] {
  border: 1px solid;
  display: flex;
}

.description-arrow[data-v-40224f] {
  background: #8dcc8d;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
  display: flex;
}

.not-continuous[data-v-40224f] {
  text-align: center;
  cursor: help;
  background: #c30;
  border: 1px solid;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.toggle[data-v-a5b1af] {
  cursor: pointer;
}

.settings input[type="submit"][data-v-5d76ab] {
  background: #add8e6;
  width: 100%;
  font-weight: bold;
}

.commits[data-v-5d76ab] {
  border: none;
  padding: 0;
  display: flex;
}

.commit-input[data-v-5d76ab] {
  flex-direction: column;
  width: 270px;
  display: flex;
}

.commit-input label[data-v-5d76ab] {
  margin-bottom: 6px;
  font-size: 12px;
  font-weight: bold;
}

.metric[data-v-5d76ab] {
  height: 40px;
  position: relative;
}

.stats[data-v-5d76ab] {
  border-radius: 5px;
  width: 200px;
  margin-left: 52px;
  font-size: 14px;
}

.tab[data-v-9ee5a3] {
  text-align: center;
  cursor: pointer;
  border: 2px dotted #ccc;
  border-radius: 5px;
  flex-direction: column;
  min-width: 200px;
  min-height: 60px;
  padding: 5px;
  display: flex;
  position: relative;
}

@media (width >= 600px) {
  .tab[data-v-9ee5a3]:not(:first-child) {
    margin-left: 30px;
  }

  .tab[data-v-9ee5a3]:not(:last-child) :after {
    content: "";
    border-right: 1px solid #000;
    position: absolute;
    top: 20%;
    bottom: 20%;
    right: -17px;
  }
}

.title[data-v-9ee5a3] {
  margin-bottom: 5px;
  font-size: 1.1em;
  font-weight: bold;
}

.selected[data-v-9ee5a3], .tab[data-v-9ee5a3]:hover {
  border-style: solid;
  border-color: #000;
}

.summary[data-v-9ee5a3] {
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  font-size: .9em;
  display: flex;
}

.wrapper[data-v-6d3be6] {
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  display: flex;
}

@media (width >= 600px) {
  .wrapper[data-v-6d3be6] {
    flex-direction: row;
    justify-content: center;
    align-items: normal;
  }
}

.table-wrapper table[data-v-6d3be6] {
  table-layout: auto;
  width: 100%;
}

.table-wrapper th[data-v-6d3be6] {
  font-weight: normal;
}

.wrapper[data-v-ecb30e] {
  flex-flow: wrap;
  align-items: center;
  margin: 10px 0;
  display: flex;
}

.wrapper div[data-v-ecb30e] {
  margin-right: 10px;
}

.wrapper .active[data-v-ecb30e] {
  font-weight: bold;
}

.wrapper .label[data-v-ecb30e] {
  display: none;
}

@media (width >= 600px) {
  .wrapper .label[data-v-ecb30e] {
    display: block;
  }
}

.tooltip[data-v-733639] {
  text-align: center;
  cursor: pointer;
  background: #d6d3d3;
  border: 1px solid #7d6969;
  border-radius: 50%;
  width: 16px;
  margin: 0 4px;
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
  position: relative;
}

.tooltip .tooltiptext[data-v-733639] {
  visibility: hidden;
  color: #fff;
  text-align: center;
  opacity: 0;
  background-color: #524d4d;
  border-radius: 6px;
  width: 180px;
  margin-left: -80px;
  padding: 5px;
  transition: opacity .3s, visibility .3s;
  position: absolute;
  bottom: 125%;
}

@media screen and (width <= 600px) {
  .tooltiptext[data-v-733639] {
    width: 120px;
    margin-left: -60px;
  }
}

.tooltip:hover .tooltiptext[data-v-733639] {
  visibility: visible;
  opacity: 1;
}

.section-heading[data-v-42190e] {
  font-size: 16px;
}

#filter[data-v-42190e] {
  margin-left: 52px;
}

.states-list[data-v-42190e] {
  flex-direction: column;
  align-items: start;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.section-list-wrapper[data-v-42190e] {
  flex-direction: column;
}

@media (width >= 760px) {
  .states-list[data-v-42190e] {
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: 80%;
  }

  .section-list-wrapper[data-v-42190e] {
    flex-direction: row;
  }
}

.states-list > li[data-v-42190e] {
  margin-right: 15px;
}

.label[data-v-42190e] {
  font-weight: bold;
}

.regex-toggle[data-v-42190e] {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-left: 5px;
  padding: 2px 6px;
  font-size: 12px;
  font-weight: bold;
  position: relative;
}

.regex-toggle.active[data-v-42190e] {
  background-color: #bed6ed;
  border-color: #005fb8;
}

.regex-toggle:hover .regex-tooltiptext[data-v-42190e] {
  visibility: visible;
  opacity: 1;
}

.regex-tooltiptext[data-v-42190e] {
  visibility: hidden;
  color: #fff;
  text-align: center;
  opacity: 0;
  background-color: #524d4d;
  border-radius: 6px;
  width: 180px;
  margin-left: -60px;
  padding: 5px;
  transition: opacity .3s, visibility .3s;
  position: absolute;
  bottom: 125%;
}

@media screen and (width <= 600px) {
  .regex-tooltiptext[data-v-42190e] {
    width: 120px;
    margin-left: -180px;
  }
}

.summary-table td[data-v-9019fd] {
  text-align: right;
}

.summary-table td[data-v-9019fd], .summary-table th[data-v-9019fd] {
  vertical-align: middle;
  padding: 2px 5px;
}

.main-summary[data-v-a518c6] {
  border: 1px dashed;
  border-radius: 6px;
  justify-content: center;
  padding: 4px;
  display: flex;
  position: relative;
}

.tooltip-container[data-v-a518c6] {
  position: absolute;
  top: 5px;
  right: -7px;
}

[data-v-a518c6] .tooltiptext {
  margin-left: -178px !important;
}

@media screen and (width <= 600px) {
  .tooltip-container[data-v-a518c6] {
    left: 5px;
  }

  [data-v-a518c6] .tooltiptext {
    margin-left: -22px !important;
  }
}

pre[data-v-e6d164] {
  white-space: pre-wrap;
  word-wrap: break-word;
  background-color: #eee;
  border-radius: 10px;
  padding: 10px 10px 10px 15px;
}

.title[data-v-47a01d] {
  font-weight: bold;
}

.columns[data-v-1582f8] {
  flex-wrap: wrap;
  gap: 15px;
  margin: 10px 0;
  display: flex;
}

.columns .grow[data-v-1582f8] {
  flex-grow: 1;
}

.graphs[data-v-1582f8] {
  margin-top: 15px;
}

.rows[data-v-1582f8] {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.rows.center-items[data-v-1582f8] {
  align-items: center;
}

.title.bold[data-v-1582f8], .title .bold[data-v-1582f8] {
  font-weight: bold;
}

.title.info[data-v-1582f8] {
  margin-bottom: 15px;
}

.columns[data-v-e7fd49] {
  flex-wrap: wrap;
  gap: 15px;
  margin: 10px 0;
  display: flex;
}

.columns .grow[data-v-e7fd49] {
  flex-grow: 1;
}

.graphs[data-v-e7fd49] {
  margin-top: 15px;
}

.rows[data-v-e7fd49] {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.rows.center-items[data-v-e7fd49] {
  align-items: center;
}

.title.bold[data-v-e7fd49], .title .bold[data-v-e7fd49] {
  font-weight: bold;
}

.title.info[data-v-e7fd49] {
  margin-bottom: 15px;
}

.shortcut[data-v-e7fd49] {
  text-align: left;
  margin-top: 15px;
}

.benchmark-container[data-v-e7fd49] {
  align-items: center;
  display: flex;
}

.benchmark-label[data-v-e7fd49] {
  text-align: left;
  flex: 0;
  padding-right: 10px;
  font-weight: bold;
}

.benchmark-value[data-v-e7fd49] {
  text-align: center;
  overflow-wrap: anywhere;
  flex: 1;
}

.links li[data-v-e7fd49] {
  text-align: left;
}

.u-tooltip {
  pointer-events: none;
  z-index: 100;
  white-space: pre;
  background: #fff;
  border: 2px solid #000;
  padding: 4px;
  font-family: monospace;
  font-size: 10pt;
  display: none;
  position: absolute;
}

.toggle[data-v-4bceff] {
  cursor: pointer;
}

.toggle .toggle-arrow[data-v-4bceff] {
  width: 5px;
  padding-right: 5px;
}

.toggle[data-v-4bceff]:hover, .toggle.toggled[data-v-4bceff] {
  background-color: #d6d3d35c;
}

.benches[data-v-283ed7] {
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
  font-size: medium;
}

.benches td[data-v-283ed7], .benches th[data-v-283ed7] {
  padding: .3em;
}

.benches tbody[data-v-283ed7]:before {
  content: "";
  height: 10px;
  display: block;
}

.benches tbody:first-child th[data-v-283ed7] {
  text-align: center;
}

.benches tbody:not(:first-child) th[data-v-283ed7] {
  border-right: 1px dotted;
}

.benches td[data-v-283ed7], .benches th[data-v-283ed7] {
  text-align: center;
}

.benches td.toggle-arrow[data-v-283ed7], .benches th.toggle-arrow[data-v-283ed7] {
  width: 5px;
  padding-right: 5px;
}

.benches td.narrow[data-v-283ed7], .benches td.pct-change[data-v-283ed7], .benches th.narrow[data-v-283ed7], .benches th.pct-change[data-v-283ed7] {
  word-wrap: break-word;
  width: 90px;
}

.benches td.raw-data[data-v-283ed7], .benches th.raw-data[data-v-283ed7] {
  text-align: right;
  width: 90px;
}

.benches td > .numeric-aligned[data-v-283ed7] {
  text-align: right;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.benches td > .numeric-aligned > div[data-v-283ed7], .benches td > .numeric-aligned > span[data-v-283ed7] {
  width: 60px;
}

.benches td.numeric[data-v-283ed7] {
  text-align: right;
}

.bench-table[data-v-283ed7] {
  margin-top: 10px;
}

.silent-link[data-v-283ed7] {
  color: inherit;
}

.benchmark_name[data-v-283ed7] {
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 1rem;
  overflow: hidden;
}

abbr[data-v-283ed7] {
  text-decoration-thickness: from-font;
}

[data-v-283ed7] .tooltiptext {
  margin-left: -169px !important;
}

.wrapper[data-v-e8e7a7] {
  justify-content: center;
  display: flex;
}

table[data-v-e8e7a7] {
  table-layout: fixed;
  margin-top: 10px;
}

table td[data-v-e8e7a7], table th[data-v-e8e7a7] {
  text-align: center;
  padding: .3em;
}

table .component[data-v-e8e7a7] {
  word-break: break-word;
}

table .aligned[data-v-e8e7a7] {
  text-align: right;
}

@media (width >= 600px) {
  table .aligned[data-v-e8e7a7] {
    width: 120px;
  }
}

table .aligned-header[data-v-e8e7a7] {
  text-align: right;
}
/*# sourceMappingURL=compare.css.map */
